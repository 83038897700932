import { StyleSheet, View, ActivityIndicator } from 'react-native';
import React, { useEffect } from 'react';
import { Router, Routes, Route } from "./react-router";


export function Redirecter({to} : {to?: string}) {
  useEffect(() => {
    if (to) {
      window.location.href = to
    } else {
      let path = window.location.href;
      path = path.replace('https://blastview.io', 'https://app.blastview.io')
      path = path.replace('https://www.blastview.io', 'https://app.blastview.io')
      path = path.replace('https://temp.blastview.io', 'https://app.blastview.io')
      path = path.replace('http://localhost:19006', 'https://app.blastview.io')
      window.location.href = path
    }
  }, [])

  return (
    <View style={styles.container}>
      <ActivityIndicator color="#102A9C" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Redirecter to="https://home.blastview.io" />} />
        <Route
          path="*"
          element={<Redirecter />}
        />
      </Routes>
    </Router>
  );
}
